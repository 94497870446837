body {
    background-color: #040c1b;
    color: #ffffff;
    font-size: large;
    /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
    /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif, Courier, monospace */
     font-family: sans-serif
    /*font-family: 
    font-family: 
    font-family: 
    font-family:  */
}

/* Smaller font for phones */
@media only screen 
and (max-width : 500px) {
    body {
        font-size: medium;
        /* color: hsl(0, 100%, 50%); */
    }
}


/* Go to root document -> Change all fonts to inter */
/* Go to root document and define the colors */
:root {
    /* font-family: 'Inter'; */
    --primary-color: white;
}

/* The below prevents overflow in all pages so that we don't get horizontal scrollbar that is not intended */
#root {
    overflow-x: hidden;
}

.justify-text {
    text-align: justify;
    text-justify: auto;
}

/* Justify content centers along the main axis */
/* Align-items centers along the cross axis */
.text-right {
    display: flex;
    justify-content: flex-end;
}

.text-center {
    text-align: center;
}

.text-center-vertical {
  display: flex;
  align-items: center;
}

.read-or-hide {
    color: rgb(192, 192, 192);
    cursor: pointer;
}

/* The three below are from bootstrap. I'm overwriting their settings */
.carousel-control-prev-icon {
    filter: invert(100%);
/* background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important; */
}
.carousel-control-next-icon {
    filter: invert(100%);
    /* background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
    color: "red" */
}
.carousel-indicators button {
    background-color: black!important;
}

/* .carousel-control-next-icon{
    background-color: green;
} */
/* .carousel-control-prev-icon{
    background-color: green;
}
/*


/* .carousel .carousel-indicators button {
    background-color: #3c9feb;
    border: none;
    border-radius: 50%;
    width: 10px;
    height: 10px;
  } */

.color-gradient {
    color: rgb(65, 129, 224)
    /* background: linear-gradient(to right, rgb(18, 124, 231), rgb(17, 65, 224)); */
    /* background: linear-gradient(to right, rgb(176, 28, 166), rgb(224, 17, 207)); */
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

.bold {
    font-weight: bold;
}

.education-date {
    color: rgb(165, 165, 165);
    font-style: italic;
}

.separator {
    border-top: 1px solid black; /* Define the border style */
    margin: 10px 0; /* Adjust margin as needed */
  }

/* :root * {
    transition: all ease-in-out 0.3
} */

/* body {
    background-color: #dadada;
    color: #1f2023;
} */
/* This is if you want to make the color adjust according to the user settings */
/* @media (prefers-color-scheme: dark) {
body {
    background-color: #1f2023;
    color: #dadada;
}
} */

.publications-container {
    /* background-color: blueviolet; */
    border-color: rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    border-style: solid;
    /* border-width: 1px; */
    padding: 10px;
    margin-bottom: 50px;
    /* Overflow hidden solves the issue of horizontal scrolling on phones. I have added this to the main CSS */
    /* overflow : hidden;  */
    /* margin-top: 200px; */
}

/* The below applies properties to all children of the class */
.publications-container > * {
    /* margin: 300px 0px; */
    margin-bottom: 50px;
}